export const mode = {
  selection: 'selection',
  manualRect: 'manualRect',
  splitVertical: 'splitVertical',
  splitHorizontal: 'splitHorizontal',
  additionalZone: 'additionalZone',
  deletion: 'deletion',
  mdiv: 'mdiv',
  login: 'login'
}
